import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { Layout } from 'components/Layout'
import { store } from '@/setup'

import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './styles/styles.css'

import { unregister } from './registerServiceWorker'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

root.render(
  <Provider store={store}>
    <Layout />
  </Provider>,
)

unregister()
