import { post, get as apiGet } from 'api'

export const get = async () => {
  return apiGet(`/Company/GetCompanySettings`)
}

export const update = async values => {
  const formData = new FormData()
  for (const property in values) {
    formData.append(property, values[property])
  }
  return post('/Company/SaveCompanySettings', formData)
}
