import React from 'react'
import Autosuggest from 'react-autosuggest'
import { useFormField } from '../useFormField'

export const AutoSuggest = allProps => {
  const {
    props,
    values,
    formGroupClasses,
    name,
    fieldError,
    className,
    label,
    labelClasses,
    fromLibraries: { classnames, Field },
  } = useFormField(allProps)
  const {
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    shouldRenderSuggestions,
    onChange,
    ...restProps
  } = props
  return (
    <Autosuggest
      {...props}
      shouldRenderSuggestions={shouldRenderSuggestions || (value => value.trim().length > 1)}
      inputProps={{
        value: values[name],
        onChange: onChange,
      }}
      renderInputComponent={({ onChange, ...rest }) => {
        return (
          <div {...rest}>
            <div className={classnames('mb-3', formGroupClasses)}>
              {label && (
                <label htmlFor={name} className={classnames(labelClasses, 'form-label')}>
                  {label}
                </label>
              )}
              <Field
                id={name}
                {...restProps}
                autoComplete="off"
                className={classnames('form-control', fieldError && 'is-invalid', className)}
                onChange={onChange}
              />
              {fieldError && <small className="form-text text-danger">{fieldError}</small>}
            </div>
          </div>
        )
      }}
    />
  )
}
