import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from './api'

export const getAll = createAsyncThunk('template/GET_ALL', async (payload, thunkAPI) => {
  const { data, error } = await api.getAll(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const get = createAsyncThunk('template/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) thunkAPI.rejectWithValue(error)
  return data
})

export const update = createAsyncThunk('template/UPDATE', async (payload, thunkAPI) => {
  const { data, error } = await api.update(payload)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(getAll(true))

  return data
})

export const deleteItem = createAsyncThunk('template/DELETE_ITEM', async (payload, thunkAPI) => {
  const { data, error } = await api.deleteItem(payload)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(getAll(true))
  return data
})

const initialState = {
  error: null,
  model: null,
  isModelLoading: false,
  isUpdated: false,
  list: [],
  isListLoading: false,
}

export const slice = createSlice({
  name: 'template',
  initialState: initialState,
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearMessages: state => {
      state.isUpdated = false
    },
    reset: () => initialState,
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })
      .addCase(update.pending, state => {
        // state.isUpdating = true
        state.isUpdated = false
        state.error = null
      })
      .addCase(update.fulfilled, (state, action) => {
        // state.isUpdating = false
        state.isUpdated = true
        state.model = action.payload
      })
      .addCase(update.rejected, (state, action) => {
        // state.isUpdating = false
        state.isUpdated = false
        state.error = action.error
      })
      .addCase(deleteItem.pending, state => {
        state.isDeleting = true
        state.error = null
      })
      .addCase(deleteItem.fulfilled, state => {
        state.isDeleting = false
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.isDeleting = false
        state.error = action.error
      }),
  ],
})

export const { clearError, clearMessages, reset } = slice.actions

export const listSelector = state => state.template.list
export const isListLoadingSelector = state => state.template.isListLoading
export const errorSelector = state => state.template.error
export const modelSelector = state => state.template.model
export const isModelLoadingSelector = state => state.template.isModelLoading
export const isUpdatedSelector = state => state.template.isUpdated

export const reducer = slice.reducer
