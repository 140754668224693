import { withPrefix } from 'i18n/translation'
import { useRef, useState } from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { useFormField } from '../useFormField'

export const PasswordField = allProps => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const [showTooltip, setShowTooltip] = useState(false)
  const toolTipTarget = useRef(null)
  const pt = withPrefix('scenes.user')

  const validations = [
    pt('atLeastOneUpperCase'),
    pt('atLeastOneLowerCase'),
    pt('atLeastOneNumber'),
    pt('atLeastOneSpecialCharacter'),
    pt('atLeast12CharactersLong'),
  ]
  const { props, setFieldTouched, setFieldValue, label, labelClasses, name, fieldError } = useFormField(allProps)

  const { disabled, withTooltip } = props
  const handleChange = (name, value) => {
    setFieldValue(name, value.target.value).then(() => setFieldTouched(name))
  }
  return (
    <div className="mb-3">
      {label && (
        <>
          <label htmlFor={name} className={labelClasses} style={{ marginBottom: '0.5rem' }}>
            {label}
          </label>
          {withTooltip && (
            <>
              <span
                style={{ cursor: 'pointer' }}
                className="pe-0 text-end ps-2"
                onClick={() => setShowTooltip(!showTooltip)}
              >
                <i ref={toolTipTarget} className="bi bi-exclamation-circle m-0"></i>
              </span>
              <Overlay
                rootClose={true}
                onHide={() => setShowTooltip(false)}
                target={toolTipTarget.current}
                show={showTooltip}
                placement="top"
              >
                {props => (
                  <Tooltip id="overlay-example" {...props}>
                    <ul className="ps-3 m-0">
                      {validations.map(v => (
                        <li key={v.trim()} className="text-start m-0 p-0">
                          {v}
                        </li>
                      ))}
                    </ul>
                  </Tooltip>
                )}
              </Overlay>
            </>
          )}
        </>
      )}
      <div className="d-flex flex-direction align-items-center">
        <div className={`input-group overflow-hidden p-0 ${fieldError && 'border border-danger rounded'}`}>
          <input
            autoComplete="off"
            onChange={value => handleChange(name, value)}
            type={passwordVisible ? 'text' : 'password'}
            id={name}
            disabled={disabled}
            className={`form-control ${fieldError ? 'border-0' : 'border-end-0'}`}
          />
          <span className="bg-white border-0" onClick={() => setPasswordVisible(prev => !prev)}>
            <span
              className={`input-group-text bg-transparent ${fieldError ? 'border-0' : 'border'}`}
              style={{
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
            >
              {passwordVisible ? (
                <i className="bi bi-eye" style={{ width: '1rem' }}></i>
              ) : (
                <i className="bi bi-eye-slash" style={{ width: '1rem' }} aria-hidden="true"></i>
              )}
            </span>
          </span>
        </div>
      </div>
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
