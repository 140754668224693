import { useRef } from 'react'
import ToastItem from '../ToastItem'
import './styles.css'

const ToastList = ({ data, position }) => {
  const listRef = useRef(null)

  const sortedData = position.includes('bottom') ? [...data].reverse() : [...data]
  return (
    sortedData.length > 0 && (
      <div className={`toast-list toast-list--${position}`} aria-live="assertive" ref={listRef}>
        {sortedData.map(toast => (
          <ToastItem key={toast.id} message={toast.message} type={toast.type} id={toast.id} data={data} />
        ))}
      </div>
    )
  )
}

export default ToastList
