import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserInfo } from 'components/AppHeader/components/UserInfo'
import { routes } from 'routes'
import { CompanySelect } from './components/CompanySelect/index.jsx'
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap'

import { isAuthenticatedSelector } from 'services/auth/slice'
import { emailInfoSelector } from 'services/email/slice'
import {
  modelSelector as companyInfoSelector,
  companyLogoSelector,
  isLogoLoadingSelector,
} from 'services/settings/slice'

import { LanguageSelect } from 'components/LanguageSelect'

import { useSelector } from 'react-redux'
import logoUrl from '../../static/uniout-logo.png'

export const AppHeader = props => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const companyInfo = useSelector(companyInfoSelector)
  const companyLogo = useSelector(companyLogoSelector)
  const isLogoLoading = useSelector(isLogoLoadingSelector)
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const emailInfo = useSelector(emailInfoSelector)
  const toggle = () => setIsOpen(!isOpen)

  const { publicMode, smallLogo } = props
  const { logoFileName, name } = companyInfo || {}

  const logo = logoFileName && companyLogo ? companyLogo : logoUrl
  return (
    <Navbar expand="sm" className="app-header">
      <NavbarBrand
        onClick={() => {
          navigate(isAuthenticated ? routes.root.path : routes.login.path)
        }}
      >
        {!isLogoLoading && <img style={{ height: '2.7rem' }} src={logo} title={name} />}
      </NavbarBrand>
      <NavbarToggler onClick={toggle}>
        <span className="navbar-toggler-icon">
          <i style={{ lineHeight: '1em !important' }} className="fas fa-bars fa-lg" />
        </span>
      </NavbarToggler>
      <Collapse isOpen={true} navbar>
        <Nav className="ms-auto" navbar>
          <div className="mx-2">
            <LanguageSelect publicMode={publicMode && !smallLogo} emailInfo={emailInfo} />
          </div>
          <div className="mx-2">{!publicMode && <CompanySelect />}</div>
          <div className="mx-2">{!publicMode && <UserInfo />}</div>
        </Nav>
      </Collapse>
    </Navbar>
  )
}
