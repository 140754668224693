import en from 'date-fns/locale/en-US'
import sr from 'date-fns/locale/sr'
import srLatn from 'date-fns/locale/sr-Latn'
import { currentLocale } from 'i18n/translation'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useFormField } from '../useFormField'

import moment from 'moment'

registerLocale('en-us', en)
registerLocale('sr-sl', srLatn)
registerLocale('sr-sc', sr)

export const CustomDatePicker = allProps => {
  const {
    props,
    setFieldTouched,
    setFieldValue,
    name,
    formGroupClasses,
    labelClasses,
    field,
    fieldError,
    label,

    fromLibraries: { classnames },
  } = useFormField(allProps)
  const { placeholder, isEndDateTime } = props
  const handleChange = (name, value) => {
    const date = new Date(value)

    let formattedDate
    formattedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
    if (isEndDateTime) {
      formattedDate = moment
        .utc(date, 'YYYY-MM-DDTHH:mm:ss.000000[Z]')
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
    }
    setFieldValue(name, formattedDate).then(() => setFieldTouched(name))
  }

  const convertUTCToLocalDate = date => {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }
  return (
    <div className={classnames('mb-3', formGroupClasses)}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <div className="d-block">
        <DatePicker
          id={name}
          // {...field}
          className={classnames(fieldError && 'is-invalid')}
          selected={field.value ? convertUTCToLocalDate(new Date(field.value)) : null}
          {...props}
          onChange={value => handleChange(name, value)}
          autoComplete="off"
          customInput={<input type="text" className="form-control" />}
          locale={currentLocale}
          placeholderText={placeholder}
          // popperPlacement="top-end"
        />
      </div>
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
