import React from 'react'
import { useFormField } from '../useFormField'

export const CheckBox = allProps => {
  const {
    formGroupClasses,
    className,
    style,
    field,
    labelClasses,
    label,
    props,
    name,
    handleChange,
    fromLibraries: { classnames, Field },
  } = useFormField(allProps)
  const { customOnChange } = props
  return (
    <div className={classnames('mb-3', formGroupClasses, className)} style={style}>
      <div className="form-check">
        <label>
          <Field
            onChange={e => {
              handleChange(e)
              customOnChange && customOnChange(e)
            }}
            {...props}
            id={name}
            value={field.value}
            checked={field.value}
            className="form-check-input"
          />
          <span className={classnames(labelClasses, 'form-check-label ')}>{label}</span>
        </label>
      </div>
    </div>
  )
}
