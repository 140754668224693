import { filter, map, path, pipe, prop, sortBy } from 'ramda'

import { locales, translations } from 'i18n/translation'

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { languageSelector, selectLanguage } from 'services/account/slice'

const sortByLabel = sortBy(prop('label'))
const filterWithLabel = filter(prop('label'))
const filterVisible = filter(prop('visible'))

const makeLanguageLabels = (l, t) =>
  pipe(
    map(locale => ({
      locale,
      label: path([locale, 'app.language.short'], t) || '',
      visible: path([locale, 'app.language.visibleInMenu'], t) === 'TRUE',
    })),
    filterVisible(),
    sortByLabel(),
    filterWithLabel(),
  )(l)

const LanguageSelect = () => {
  const language = useSelector(languageSelector)
  const dispatch = useDispatch()

  const languageLabels = makeLanguageLabels(locales, translations)
  const selected = prop(
    'label',
    languageLabels.find(l => l.locale === language),
  )

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <i className="fas fa-thin fa-globe pe-2"></i>
        {selected}
      </DropdownToggle>
      <DropdownMenu end>
        {languageLabels.map(({ locale, label }) =>
          locale === language ? null : (
            <DropdownItem
              key={locale}
              onClick={() => {
                dispatch(selectLanguage(locale))
              }}
            >
              {label}
            </DropdownItem>
          ),
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export { LanguageSelect }
