import ReactSelect from 'components/ReactSelect'
import React from 'react'
import { useFormField } from '../useFormField'

export const ReactSelectField = allProps => {
  const {
    props,
    setFieldTouched,
    field,
    setFieldValue,
    label,
    labelClasses,
    formGroupClasses,
    name,
    fieldError,
    fromLibraries: { classnames },
  } = useFormField(allProps)
  const {
    options: propOptions,
    keyProp,
    text,
    emptyText,
    isMulti,
    isClearable,
    placeholder,
    isSearchable,
    readOnly,
    onChange,
    validate,
    ...rest
  } = props
  const { onCreateOption } = props
  const handleChange = (name, value, meta) => {
    setFieldValue(name, value).then(() => setFieldTouched(name))
    onChange && onChange(value, meta)
  }
  return (
    <div className={classnames(formGroupClasses, 'mb-3')}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <ReactSelect
        options={propOptions}
        keyProp={keyProp}
        text={text}
        emptyText={emptyText}
        value={field.value}
        onChange={(value, meta) => handleChange(name, value, meta)}
        isMulti={isMulti}
        isClearable={isClearable || false}
        placeholder={placeholder || ''}
        onCreateOption={onCreateOption}
        fieldError={fieldError}
        isSearchable={isSearchable}
        isDisabled={readOnly}
        {...rest}
      />
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
