import React from 'react'
import { useFormField } from '../useFormField'

export const InputField = React.forwardRef((allProps, ref) => {
  const {
    props,
    label,
    labelClasses,
    autoFocus,
    component,
    className,
    formGroupClasses,
    name,
    fieldError,
    fromLibraries: { classnames, Field },
  } = useFormField(allProps)
  return (
    <div className={classnames('mb-3', formGroupClasses)}>
      {label && (
        <label htmlFor={name} className={classnames(labelClasses, 'form-label')}>
          {label}
        </label>
      )}
      <Field
        id={name}
        autoFocus={autoFocus ?? false}
        {...props}
        innerRef={ref}
        component={component}
        className={classnames('form-control', fieldError && 'is-invalid', className)}
      />
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
})

InputField.displayName = 'InputField'
