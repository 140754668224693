import React from 'react'
import { useSelector } from 'react-redux'
import { toastConfigSelector, toastsSelector } from 'services/toast/slice'
import ToastList from './components/ToastList'

const ToastWrapper = () => {
  const toasts = useSelector(toastsSelector)
  const config = useSelector(toastConfigSelector)

  return <ToastList data={toasts} position={config.position} />
}

export default ToastWrapper
