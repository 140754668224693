import React from 'react'
import { useFormField } from '../useFormField'

export const Switch = allProps => {
  const {
    setFieldTouched,
    setFieldValue,
    name,
    values,
    label,
    props: { disabled },
  } = useFormField(allProps)
  const onSwitchClick = (e, name) => {
    setFieldValue(name, !values[name]).then(() => setFieldTouched(name))
  }
  return (
    <div className="form-check form-switch" style={{ zIndex: 'auto' }}>
      <input
        type="checkbox"
        onChange={e => onSwitchClick(e, name)}
        className="form-check-input"
        id={name}
        name={name}
        checked={values[name]}
        disabled={disabled}
        // defaultChecked={values[name]}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}
