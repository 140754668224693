import React from 'react'
import ReactMultiSelect from '../../ReactMultiSelect'
import { useFormField } from '../useFormField'

export const ReactMultiSelectField = allProps => {
  const {
    props,
    setFieldTouched,
    setFieldValue,
    label,
    labelClasses,
    formGroupClasses,
    name,
    values,
    fieldError,
    fromLibraries: { classnames },
  } = useFormField(allProps)
  const { options, closeMenuOnSelect, hideSelectedOptions, onChange, placeholder, disabled } = props
  const handleChange = (name, value) => {
    setFieldValue(name, value).then(() => setFieldTouched(name))
    onChange && onChange(value, name)
  }
  return (
    <div className={classnames('mb-3 w-100', formGroupClasses)}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <ReactMultiSelect
        options={options}
        isMulti={true}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        onChange={value => handleChange(name, value)}
        allowSelectAll={true}
        value={values[name]}
        fieldError={fieldError}
        menuPlacement="auto"
        placeholder={placeholder || 'Select...'}
        disabled={disabled}
      />
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
