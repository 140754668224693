import { withPrefix } from 'i18n/translation'
import { useDispatch } from 'react-redux'
import { keepToastOpen, refreshToastsTimeout, removeToast } from 'services/toast/slice'
import './styles.css'

import { CloseIcon, FailureIcon, InfoIcon, SuccessIcon, WarningIcon } from '../../../../icons/Icons'

const ToastItem = ({ message, type, id }) => {
  const ptf = withPrefix('errorCode')
  const pts = withPrefix('successMessage')
  const pti = withPrefix('info')
  const dispatch = useDispatch()
  const iconMap = {
    success: <SuccessIcon />,
    failure: <FailureIcon />,
    warning: <WarningIcon />,
    info: <InfoIcon />,
  }

  const toastIcon = iconMap[type] || null
  return (
    <div
      className={`custom-toast custom-toast--${type}`}
      role="alert"
      onMouseEnter={() => dispatch(keepToastOpen(id))}
      onMouseLeave={() => {
        dispatch(keepToastOpen(id))
      }}
    >
      <div className="custom-toast-message">
        {toastIcon && <div className="icon icon--lg icon--thumb">{toastIcon}</div>}
        <p>
          {type === 'success' && pts(message)}
          {type === 'failure' && ptf(message)}
          {type === 'info' && pti(message)}
        </p>
      </div>
      <button className="custom-toast-close-btn" onClick={() => dispatch(removeToast(id))}>
        <span className="icon">
          <CloseIcon />
        </span>
      </button>
    </div>
  )
}

export default ToastItem
